<template>
  <div>
    <div class="wz">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>招聘负责人列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="box">
      <div class="serarch">
        <div class="searchleft">
          <el-form :inline="true" :model="searchform" class="demo-form-inline">
            <el-form-item class="input-item">
              <el-input
                placeholder="请输入招聘负责人姓名/手机号"
                v-model.trim="inputSeache"
                @keyup.enter.native="pageNum=1;getAdminUser()"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search lh"
                  @click="pageNum=1;getAdminUser()"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item class="input-item">
              <el-input
                placeholder="请输入招聘负责人身份证号"
                v-model.trim="inputSeache1"
                @keyup.enter.native="pageNum=1;getAdminUser()"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search lh"
                  @click="pageNum=1;getAdminUser()"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-select
                v-model="userGender"
                @change="pageNum=1;getAdminUser()"
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="userStatus"
                @change="pageNum=1;getAdminUser()"
                placeholder="全部"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="已认证" value="1"></el-option>
                <el-option label="认证中" value="0"></el-option>
                <el-option label="已拒绝" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="searchright">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="addUserBtn"
            >创建招聘负责人</el-button
          >
        </div>
      </div>
      <div class="list" v-loading="loading">
        <el-table
          :data="tableData"
          :header-cell-style="{
            'font-weight': 'bold',
            color: '#555555',
            padding: '6px 0',
          }"
          style="width: 100%"
          v-loading="loading"
          border
          stripe
          row-key="id"
        >
          <el-table-column prop="id" align="center" label="ID" width="100">
          </el-table-column>
          <el-table-column
            prop="legalName"
            align="center"
            label="姓名"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            align="center"
            label="手机号"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="legalIdNumber"
            align="center"
            label="身份证号"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="sex" align="center" label="性别" width="150">
            <template slot-scope="scope">
              {{ scope.row.sex == 1 ? "男" : "女" }}
              <i
                v-if="scope.row.sex == 1"
                class="el-icon-male"
                style="color: rgb(30 144 255)"
              ></i>
              <i
                v-else
                class="el-icon-female"
                style="color: rgb(255 106 106)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column prop="age" align="center" label="年龄" width="150">
          </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
            width="200"
          >
          </el-table-column>
          <el-table-column prop="status" align="center" label="认证状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0" type="warning" 
                >认证中</el-tag
              >
              <el-tag
                v-else-if="scope.row.status == 1"
                type="success"
                >已认证</el-tag
              >
              <el-tag v-else type="danger" 
                >已拒绝</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column prop="" width="250" align="center" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="shenhe(scope.row.id)"
                >审核</el-button
              >
              <el-button
                type="primary"
                @click="handleCreate(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                @click="deleteUser(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <app-page
        :total="total"
        :pageSize="pageSize"
        :current="pageNum"
        @handleCurrentChange="currentchange"
        @handleSizeChange="handchange"
      ></app-page>
    </div>
    <!-- 添加 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :label-position="labelPosition"
        label-width="100px"
        :model="formAddLegal"
        :rules="rules"
        ref="formAddLegal"
      >
        <el-form-item label="招聘负责人姓名" prop="legalName">
          <el-input v-model="formAddLegal.legalName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="formAddLegal.phone"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="legalIdNumber">
          <el-input v-model="formAddLegal.legalIdNumber"></el-input>
        </el-form-item>
        <el-form-item label="身份证正面" prop="url">
          <upload-img
            :modeldata="formAddLegal"
            @changeurl="getimgurl1"
            style="margin-top: 15px"
          ></upload-img>
        </el-form-item>
        <el-form-item label="身份证反面" prop="url">
          <upload-img
            :modeldata="formAddLegalAfter"
            @changeurl="getimgurl2"
            style="margin-top: 15px"
          ></upload-img>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button @click="canvel">取 消</el-button>
          <el-button
            type="primary"
            v-preventReClick
            @click="addLegal('formAddLegal')"
            >添加</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="添加创建招聘负责人"
      :visible.sync="dialogVisible1"
      :close-on-click-modal="false"
    >
      <el-form
        :label-position="labelPosition"
        label-width="100px"
        :model="formEditLegal"
        :rules="rules"
        ref="formEditLegal"
      >
        <el-form-item label="招聘负责人姓名" prop="legalName">
          <el-input v-model="formEditLegal.legalName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="formEditLegal.phone"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="legalIdNumber">
          <el-input v-model="formEditLegal.legalIdNumber"></el-input>
        </el-form-item>
        <el-form-item label="身份证正面" prop="url">
          <upload-img
            :modeldata="formEditLegal"
            @changeurl="getimgurl3"
            style="margin-top: 15px"
          ></upload-img>
        </el-form-item>
        <el-form-item label="身份证反面" prop="url">
          <upload-img
            :modeldata="formEditLegalAfter"
            @changeurl="getimgurl4"
            style="margin-top: 15px"
          ></upload-img>
        </el-form-item>
        <el-form-item class="flex justify-content-end">
          <el-button @click="canvel1">取 消</el-button>
          <el-button
            type="primary"
            v-preventReClick
            @click="updateUser('formEditLegal')"
            >修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 认证 -->
    <el-dialog
      title="企业详情"
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancelLegal"
    >
      <el-row>
        <el-col :span="16">
          <el-row>
            <el-col :span="14">
              <div style="height: 40px">
                招聘负责人姓名：{{ legalInfo.legalName }}
              </div>
            </el-col>
          </el-row>
          <el-row style="height: 40px"
            >招聘负责人身份证号：{{ legalInfo.legalIdNumber }}</el-row
          >
          <el-row style="height: 200px">
            <el-row>招聘负责人身份证：</el-row>
            <el-col :span="9">
              <div>
                <div class="legal-img">
                  <el-image shape="square" :src="legalInfo.legalIdCardPre" style="width: 160px; height: 90px;"></el-image>
                  <div class="legal-mask">
                    <i class="el-icon-zoom-in" @click="openImgBoxWithoutUrl(legalInfo.legalIdCardPre)"></i>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="9">
              <div>
                <!-- <el-image
                  :src="legalInfo.legalIdCardPre"
                  style="width: 160px; height: 90px; margin-top: 10px"
                  :preview-src-list="[legalInfo.legalIdCardPre]"
                  fit="cover"
                >
                </el-image> -->
                <div class="legal-img">
                  <el-image shape="square" :src="legalInfo.legalIdCardAfter" style="width: 160px; height: 90px;"></el-image>
                  <div class="legal-mask">
                    <i class="el-icon-zoom-in" @click="openImgBoxWithoutUrl(legalInfo.legalIdCardAfter)"></i>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <el-row> </el-row>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <el-row>
            <div class="block">
              <div class="radio"></div>
              <el-timeline>
                <el-timeline-item
                  :timestamp="legalInfo.createTime"
                  :color="'#0bbd87'"
                  >填写完成
                </el-timeline-item>
                <el-timeline-item
                  :timestamp="legalInfo.createTime"
                  :color="'#0bbd87'"
                  >提交认证
                </el-timeline-item>
                <el-timeline-item
                  v-if="legalInfo.status == 0"
                  :color="'#efb336'"
                  >认证中
                </el-timeline-item>
                <el-timeline-item
                  v-else-if="legalInfo.status == 1"
                  :timestamp="legalInfo.updateTime"
                  :color="'#0bbd87'"
                  >认证通过
                </el-timeline-item>
                <el-timeline-item
                  v-else-if="legalInfo.status == 2"
                  :timestamp="legalInfo.updateTime"
                  :color="'#eb3409'"
                  >已拒绝
                </el-timeline-item>
              </el-timeline>
            </div>
          </el-row>
          <el-row>
            <el-button
              v-if="legalInfo.status == 0"
              type="success"
              size="mini"
              @click="makeLegalStatus(legalInfo.id, 1)"
              >通过</el-button
            >
            <el-button
              v-if="legalInfo.status == 0"
              type="danger"
              size="mini"
              @click="makeLegalStatus(legalInfo.id, 2)"
              >拒绝</el-button
            >
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 预览大图 -->
    <el-dialog
      title="预览图片"
      :visible.sync="companyImgVisible"
      width="550px">
      <div class="box-img">
        <img :src="companyImgPath" alt="" class="companyImg-path">
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/api/index";
import apiUrl from "@/api/public";
let checkMobile = (rule, value, cb) => {
    const regMobile = /^1[0-9]{10}$/
    if (regMobile.test(value)) {
    return cb()
    }
    cb(new Error('请输入合法的手机号'))
}
// 验证身份证的规则
let checkCard = (rule, value, cb) => {
    const regCard = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    if (regCard.test(value)) {
        return cb()
    }

    cb(new Error('请输入合法身份证号码'))
}
export default {
  data() {
    return {
      searchform: {
        jobName: "",
        status: "",
      },
      tableData: [], //权限列表数据
      defaultArr: [], //默认展开行
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      formAddLegal: {
        legalName: "",
        phone: "",
        legalIdNumber: "",
        url: "",
      },
      formAddLegalAfter: {
        url: "",
      },
      formEditLegal: {
        id: "",
        legalName: "",
        phone: "",
        legalIdNumber: "",
        url: "",
      },
      formEditLegalAfter: {
        url: "",
      },
      rules: {
        legalName: [
          { required: true, message: "招聘负责人姓名不能为空", trigger: "blur" },
        ],
        legalIdNumber: [
          { required: true, message: "招聘负责人身份证号不能为空", trigger: "blur" },{ validator: checkCard, trigger: 'blur' }
        ],
        phone: [{ required: true, message: "手机号不能为空", trigger: "blur" },{ validator: checkMobile, trigger: 'blur' }],
        url: [{ required: true, message: "身份证不能为空", trigger: "blur" }],
      },
      list: [],
      data: [],
      loading: false,

      labelPosition: "right", //控制表单对齐方式
      id: null,
      legalInfo: {},
      total: 0,
      pageNum: 1,
      pageSize: 10,
      inputSeache: "",
      inputSeache1: "",
      userGender: null,
      userStatus: null,
      companyImgVisible:false,
      companyImgPath:"",
    };
  },
  created() {
    this.userInfo = window.localStorage.getItem("userInfo");
    this.getAdminUser();
    this.goAddLegal();
  },
  methods: {
    getupData(url) {
      this.ruleForm.imgUrl = url;
    },
    getimgurl1(data) {
      this.formAddLegal.url = data;
    },
    getimgurl2(data) {
      this.formAddLegalAfter.url = data;
    },
    getimgurl3(data) {
      this.formEditLegal.url = data;
    },
    getimgurl4(data) {
      this.formEditLegalAfter.url = data;
    },
    // 取消
    canvel() {
      this.dialogVisible = false;
      this.formAddLegal.legalName = this.formAddLegal.legalIdNumber = this.formAddLegal.url = this.formAddLegalAfter.url = this.formAddLegal.phone =
        "";
    },
    canvel1() {
      this.dialogVisible1 = false;
    },
    addUserBtn() {
      this.dialogVisible = true;
    },
    //添加招聘负责人
    addLegal(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.formAddLegal
          const data = {
            legalName: form.legalName,
            legalIdNumber: form.legalIdNumber,
            legalIdCardPre: form.url,
            legalIdCardAfter: this.formAddLegalAfter.url,
            phone: form.phone,
            status: 1,
          };
          _api
            .get(apiUrl.checkLegal, {
              idNumber: data.legalIdNumber,
            })
            .then((res) => {
              if (res.success) {
                this.addLegal1(data);
              }
            })
            .catch((err) => {
              this.$message({
                message: "该招聘负责人已存在",
                type: "warning",
              });
            });
        } else {
          this.$message.error("信息未填写完整，请检查！");
          return false;
        }
      });
    },
    addLegal1(data) {
      _api
        .post(apiUrl.addLegal, data)
        .then((res) => {
          if (res.success) {
            this.$message.success("添加成功");
            this.canvel();
            this.getAdminUser();
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err);
        });
    },
    updateUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.formEditLegal
          const data = {
            id: form.id,
            legalName: form.legalName,
            legalIdNumber: form.legalIdNumber,
            legalIdCardPre: form.url,
            legalIdCardAfter: this.formEditLegalAfter.url,
            phone: form.phone,
          };
          _api
            .post(apiUrl.addLegal, data)
            .then((res) => {
              if (res.success) {
                this.$message.success("修改招聘负责人成功");
                this.canvel1();
                this.getAdminUser();
              }
            })
            .catch((err) => {
              this.$message.error(err)
            });
        } else {
          this.$message.error("信息未填写完整，请检查！");
          return false;
        }
      });
    },
    getAdminUser() {
      this.loading = true;
      // let data = {
      //   pageNum: this.pageNum,
      //   pageSize: this.pageSize,
      //   legalName: this.inputSeache,
      //   legalIdNumber: this.inputSeache1,
      //   sex: this.userGender,
      //   status: this.userStatus,
      //   data,
      // };
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        legalName: this.inputSeache,
        legalIdNumber: this.inputSeache1,
        sex: this.userGender,
        status: this.userStatus,
      };
      _api
        .post(apiUrl.queryLegal, data)
        .then((res) => {
          this.loading = false;
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCreate(id) {
      this.id = id;
      this.dialogVisible1 = true;
      this.selectMemberById(id);
    },
    shenhe(id) {
      this.id = id;
      this.dialogVisible2 = true;
      this.selectMemberById(id);
    },
    selectMemberById(id) {
      _api
        .get(apiUrl.queryLegalId, {
          legalId: id,
        })
        .then((res) => {
          if (res.success) {
            this.legalInfo = res.data;
            this.formEditLegal.id = res.data.id;
            this.formEditLegal.legalName = res.data.legalName;
            this.formEditLegal.legalIdNumber = res.data.legalIdNumber;
            this.formEditLegal.phone = res.data.phone;
            this.formEditLegal.url = res.data.legalIdCardPre;
            this.formEditLegalAfter.url = res.data.legalIdCardAfter;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUser(id) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          _api
            .get(apiUrl.deleteLegal, {
              legalId: id,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success("删除成功");
                this.getAdminUser();
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeUserType(type, id) {
      _api
        .post(apiUrl.changeUserType, {
          userId: id,
          type: type,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success("操作成功！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handchange(data) {
      //分页Size变化
      this.pageSize = data;
      this.pageNum = 1;
      this.getAdminUser();
    },
    currentchange(data) {
      //当前页变化
      this.pageNum = data;
      this.getAdminUser();
    },
    cancelLegal() {
      this.dialogVisible2 = false;
      this.legalInfo = {};
    },
    goAddLegal() {
      console.log(this.$route.query);
      if (this.$route.query.state) {
        this.dialogVisible = JSON.parse(this.$route.query.state);
        this.formAddLegal.legalName = this.$route.query.name;
      }
    },
    makeLegalStatus(legalId, status) {
      _api
        .post(apiUrl.makeLegalStatus, {
          legalId: legalId,
          status: status,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success(res.message);
            this.cancelLegal();
            this.getAdminUser();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openImgBoxWithoutUrl(row) {//打开照片预览大图有
      this.companyImgVisible = true
      this.companyImgPath = row
    },
  },
};
</script>
<style>
.el-loading-spinner .path {
  stroke: #fe8196;
}
.el-switch__label {
  color: #bdbdbd;
}
.el-switch__label.el-switch__label--right.is-active {
  color: #2ad1b8;
}
.el-switch__label.el-switch__label--left.is-active {
  color: #fe8496;
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/booth.css";
.serarch {
  overflow: hidden;
}
.box-img{
    display: flex;
    justify-content: center;
  }
.companyImg-path{
  max-width: 500px;
}
.legal-img{
  position: relative;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 160px;
  height: 90px;
  &:hover{
    .legal-mask{
      display: block;
    }
  }
  
  .legal-mask{
    display: none;
    cursor: pointer;
    position: absolute;
    width: 160px;
    height: 90px;
    background: rgba($color: #000, $alpha: .6);
    left: 0;
    top: 0;
    .el-icon-zoom-in{
      width: 100%;
      height: 100%;
      line-height: 90px;
      text-align: center;
      font-size: 20px;
      color: #fff;
    }
  }
}
</style>
